import Separator from "@/shared/Separator";
import { ParentGrid, InnerCardL, InnerCardM } from "./style";
import Banner from "@/widgets/Banner/Templates/Banner";
// import { sizeBanner } from "@/shared/DFP/Home/mapping";
import { GridStyleColor } from "@/widgets/Container/Templates/stylesGridGeneric.js";
import { BannerCajaGris } from "@/shared/ContentBanner";

const Container3Pisos = (props) => {
  const { dataopinion = [], link, sectionClass = "", title, title1, link1, title2, link2, title3, link3, title4, link4, linkImage } = props;

  const marfeelTitle = title ? "Container3Pisos-" + title?.normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s+/g, '-')
    .toLowerCase() : "Container3Pisos";

  const titlesExtras = [];
  if (title1 && link1) titlesExtras.push({ title: title1, link: link1 })
  if (title2 && link2) titlesExtras.push({ title: title2, link: link2 })
  if (title3 && link3) titlesExtras.push({ title: title3, link: link3 })
  if (title4 && link4) titlesExtras.push({ title: title4, link: link4 })

  return (
    props.content?.length > 0 && <>
      {title && <Separator title={title} link={link} sectionClass={sectionClass} extras={titlesExtras} linkImage={linkImage} />}
      <GridStyleColor id={marfeelTitle}>
        <ParentGrid data-mrf-recirculation={`${marfeelTitle}`} className={`Container3Pisos new module-grid ${sectionClass}`}>
          <InnerCardL className="div1">
            {props.renderContainerWidgetCallback(1, props.positionProperties?.[0]?.templateNews ?? "TwoxTwoFoto", {
              containerName: "contenedor_3_pisos"
            })}
          </InnerCardL>
          <InnerCardL className="div2">
            {props.renderContainerWidgetCallback(2, props.positionProperties?.[1]?.templateNews ?? "OnexOne", {
              containerName: "contenedor_3_pisos"
            })}
          </InnerCardL>
          <InnerCardL className="div3">
            {props.renderContainerWidgetCallback(3, props.positionProperties?.[2]?.templateNews ?? "OnexOne", {
              containerName: "contenedor_3_pisos"
            })}
          </InnerCardL>
          <InnerCardL className="div4">
            {props.renderContainerWidgetCallback(4, props.positionProperties?.[3]?.templateNews ?? "OnexOne", {
              containerName: "contenedor_3_pisos"
            })}
          </InnerCardL>
          <InnerCardL className="div5">
            {props.renderContainerWidgetCallback(5, props.positionProperties?.[4]?.templateNews ?? "OnexOne", {
              containerName: "contenedor_3_pisos"
            })}
          </InnerCardL>
          <InnerCardL className="div6">
            {props.renderContainerWidgetCallback(6, props.positionProperties?.[5]?.templateNews ?? "OnexOne", {
              containerName: "contenedor_3_pisos"
            })}
          </InnerCardL>
          <InnerCardL className="div7">
            {props.renderContainerWidgetCallback(7, props.positionProperties?.[6]?.templateNews ?? "OnexOne", {
              containerName: "contenedor_3_pisos"
            })}
          </InnerCardL>
          <InnerCardM className="div8">
            {props.renderContainerWidgetCallback(8, props.positionProperties?.[7]?.templateNews ?? "Half", {
              containerName: "contenedor_3_pisos"
            })}
          </InnerCardM>
          <InnerCardM className="div9">
            {props.renderContainerWidgetCallback(9, props.positionProperties?.[8]?.templateNews ?? "Half", {
              containerName: "contenedor_3_pisos"
            })}
          </InnerCardM>
          <div className="div10">
            <BannerCajaGris><Banner isPreview={props.isPreview} slotId="caja2" adsPath="home" /></BannerCajaGris>
          </div>
        </ParentGrid>
      </GridStyleColor>
    </>
  );
};

export default Container3Pisos;

